import React from "react";
import SbEditable from "storyblok-react";
import "slick-carousel/slick/slick.css";

import { Header } from "../Header";
import Footer from "../Footer";
import { Seo } from "../SEO";

interface Props {
  header?: HeaderTemplate;
  footer?: FooterTemplate;
  seo?: StoryblokMetaData;
  siteName: string;
  pathname: string;
  hash?: string;
  children: React.ReactNode;
  alternates?: string[];
}
export default function Layout({
  header,
  footer,
  hash,
  children,
  seo,
  pathname,
  alternates,
}: Props) {
  return (
    <React.Fragment>
      <Seo data={seo} ogType={seo?.og_type || "website"} />

      {header && (
        <SbEditable content={header}>
          <Header
            {...header}
            hash={hash}
            path={pathname}
            alternates={alternates || []}
          />
        </SbEditable>
      )}
      <main className="mt-28">{children}</main>
      {footer && (
        <SbEditable content={footer}>
          <Footer {...footer} />
        </SbEditable>
      )}
    </React.Fragment>
  );
}
