import React, { ForwardedRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import clsx from "clsx";
import { ReactComponent as CloseIco } from "@/assets/svg/close.svg";
import arrowRight from "@/assets/img/arrow-right.png";
import LinkComponent from "@/components/elements/Link";
import { ImageComponent } from "@/components/elements/Image";
import formatDate from "@/base/helpers/formatDate";

import useAuthorNews from "@/hooks/useAuthorNews";
import * as styles from "./styles.module.css";
import Socials from "./Socials";

interface Props {
  open: boolean;
  onClose: () => void;
  projectDetails: PortfolioProjectType;
  isTablet: boolean;
}

const PortfolioModal = React.forwardRef(
  (
    { open, onClose, projectDetails, isTablet }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const {
      project_description,
      project_logo,
      project_image,
      project_acquiredBy,
      project_founders = [],
      project_partner = [],
      project_socials = [],
      project_news = [],
    } = projectDetails;

    const list = useAuthorNews(project_news);

    return (
      <Modal
        open={open}
        onClose={onClose}
        center
        closeIcon={
          <CloseIco
            className="block w-6 h-6 xl:w-9 xl:h-9"
            style={{ fill: "#23282f" }}
          />
        }
        focusTrapped={false}
        classNames={{
          modal: styles.modal_wrapper,
        }}
        ref={ref}
      >
        <div className="p-6 lg:p-12 lg:pb-0 xl:p-20 xl:pb-0 2xl:p-16 2xl:pb-0">
          <div className="flex justify-between">
            <div className="lg:w-1/2 xl:w-5/12">
              <div className="mb-6 lg:mb-10 lg:flex lg:justify-between lg:items-center xl:mb-14 2xl:mb-12">
                <img
                  src={project_logo?.filename}
                  alt={project_logo?.filename || "company logo"}
                  className={styles.modal_companyLogo}
                />
                {isTablet && (
                  <Socials
                    list={project_socials}
                    acquiredBy={project_acquiredBy}
                  />
                )}
              </div>
              <p className="text-sm leading-6 max-h-60 overflow-y-auto lg:text-md lg:leading-7 xl:text-xl xl:leading-9 2xl:text-lg 2xl:leading-8">
                {project_description}
              </p>
              <div className="text-md leading-none my-6 lg:mt-6 lg:mb-11 xl:mt-8 xl:mb-14">
                {project_founders.length ? (
                  <div className="mb-4 flex">
                    <div className="mr-8">
                      <strong className="font-semibold leading-6 xl:text-xl 2xl:text-lg">
                        Founders:
                      </strong>
                    </div>
                    <div className="flex flex-wrap gap-4 leading-6">
                      {project_founders.map((ceo, i) => (
                        <React.Fragment key={ceo._uid}>
                          <LinkComponent
                            url={ceo.url}
                            type={ceo.type}
                            className={styles.link}
                          >
                            {ceo.title}
                            {i + 1 < project_founders.length ? (
                              <span>, </span>
                            ) : null}
                          </LinkComponent>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ) : null}
                {project_partner.length ? (
                  <div className="flex">
                    <div className="mr-8">
                      <strong className="font-semibold mr-2 leading-6 xl:text-xl 2xl:text-lg">
                        Partners:
                      </strong>
                    </div>
                    <div className="flex flex-wrap gap-4 leading-6">
                      {project_partner.map((el, i) => {
                        const liSocial =
                          Array.isArray(el.content.socials) &&
                          el.content.socials.find(
                            (item) => item.type === "linkedin"
                          );

                        return (
                          <React.Fragment key={el.id}>
                            {liSocial ? (
                              <LinkComponent
                                className={styles.link}
                                url={liSocial.url}
                                type="external"
                              >
                                {el.name}
                                {i + 1 < project_partner.length ? (
                                  <span>, </span>
                                ) : null}
                              </LinkComponent>
                            ) : (
                              <>
                                <span className={styles.link}>{el.name}</span>
                                {i + 1 < project_partner.length ? (
                                  <span>, </span>
                                ) : null}
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
              {!isTablet && (
                <Socials
                  list={project_socials}
                  acquiredBy={project_acquiredBy}
                />
              )}
            </div>
            {isTablet && (
              <div className="w-80 xl:w-6/12">
                {project_image && (
                  <ImageComponent
                    fluid
                    data={project_image}
                    className="w-full rounded-2xl overflow-hidden"
                    objectFit="contain"
                  />
                )}
                {project_acquiredBy && (
                  <div className="text-md leading-5 opacity-80 mt-7 text-right">
                    {<p>Acquired by: {project_acquiredBy}</p>}
                  </div>
                )}
              </div>
            )}
          </div>
          {list.length > 0 && (
            <div className="lg:border-t lg:border-border">
              <div className="flex justify-between mb-6 text-md leading-7 lg:mt-6 lg:text-md lg:leading-7 xl:mt-8 xl:text-xl xl:leading-9 2xl:text-lg 2xl:leading-8">
                <LinkComponent
                  url="news/"
                  type="internal"
                  className="text-secondary"
                >
                  See more
                </LinkComponent>
              </div>
              <ul className="lg:flex lg:flex-wrap lg:justify-between">
                {list.map((post, index) => {
                  const url = post.external_post
                    ? post.external_link
                    : post.url;
                  const type = post.external_post ? "external" : "internal";
                  return (
                    <li
                      key={post.uuid}
                      className={clsx(styles.post_wrapper, {
                        "border-b border-border": index < list.length - 1,
                      })}
                    >
                      <LinkComponent url={url} type={type} className="block">
                        <div>
                          <div className="text-sm leading-6 lg:text-md lg:leading-7 xl:text-xl xl:leading-9 2xl:text-lg 2xl:leading-8">
                            <span className="block mb-2">
                              {formatDate(post.post_date)}
                            </span>
                            <div className="flex items-center justify-between">
                              <p className="font-bold w-10/12 sm:w-11/12">
                                {post.post_title}
                              </p>
                              <img
                                src={arrowRight}
                                className="block w-6 flex-shrink-0"
                                alt="arrow right"
                              />
                            </div>
                          </div>
                        </div>
                      </LinkComponent>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </Modal>
    );
  }
);

export default PortfolioModal;
