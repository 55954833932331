import React from "react";
import ArrowRight from "@/assets/svg/arrow-right-our-story.svg";
import MarkdownComponent from "@/components/elements/Markdown";
import { useMatchMediaContext } from "@/context";
import { ContentParagraph } from "./Slider/ContentParagraph";
import { SlickSlider } from "./Slider";

export default function OurStorySection({
  label,
  title,
  content,
  slides,
}: OurStorySectionType) {
  const { isTablet } = useMatchMediaContext();

  return (
    <section className="pt-16 pb-5 lg:pb-32 xl:pb-48 bg-white">
      <div className="px-5 lg:max-w-7xl lg:block lg:w-0 lg:min-w-full">
        <div className="px-3">
          <div className="lg:max-w-x-big lg:mx-auto lg:flex lg:items-center">
            <div className="lg:w-[60%] lg:px-5 xl:w-1/2">
              <p className="text-md uppercase font-bold tracking-widest mb-5">
                {label}
              </p>
              <p className="text-4xl font-bold font-fieldwork-hum leading-none mb-7 lg:text-6xl xl:text-7xl lg:leading-snug">
                {title}
              </p>
              <div className="leading-normal mb-10 lg:mb-14 text-md md:text-lg">
                {content && (
                  <MarkdownComponent
                    overrides={{
                      p: {
                        component: ContentParagraph,
                        props: {
                          className: "mb-10",
                        },
                      },
                    }}
                  >
                    {content}
                  </MarkdownComponent>
                )}
              </div>
              {isTablet && (
                <div className="lg:flex lg:mt-4 lg:ml-16 xl:ml-0 xl:mt-12">
                  <img src={ArrowRight} alt="" className="ml-auto" />
                </div>
              )}
            </div>

            <div className="lg:w-[40%] lg:ml-8 xl:w-1/2 xl:ml-48 lg:mb-0">
              <SlickSlider slides={slides} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
