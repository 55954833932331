import { graphql, useStaticQuery } from "gatsby";
import React, { useContext, useMemo, useRef } from "react";
import sortBy from "lodash/sortBy";
import { RemoveScroll } from "react-remove-scroll";
import { useMemberModal } from "@/hooks/useMemberModal";
import { useLanguageContext } from "@/context";
import * as styles from "./styles.module.css";
import MemberModal from "./MemberModal";

function OurTeamSection({ heading, subheading }: OurTeamSectionType) {
  const teamData = useStaticQuery<{
    members: {
      edges: {
        node: StoryblokStory;
      }[];
    };
  }>(graphql`
    query TeamMembersQuery {
      members: allStoryblokEntry(
        filter: { field_component: { eq: "data_teamMember" } }
      ) {
        edges {
          node {
            content
            full_slug
          }
        }
      }
    }
  `);
  const {
    state: { language },
  } = useContext(useLanguageContext);
  const parsedMembers = useMemo(() => {
    const members =
      teamData && teamData.members
        ? teamData.members.edges
            .filter(({ node }) => {
              return node.full_slug.indexOf(`${language}/`) >= 0;
            })
            .map(({ node }) => ({
              ...(JSON.parse(node.content) as MemberType),
            }))
        : [];

    const sortedMembers = sortBy(members, (el) =>
      el.order ? parseInt(el.order, 10) : null
    );
    return {
      members: sortedMembers,
    };
  }, [language]);

  const { open, member, handleOpenModal, handleCloseModal } = useMemberModal(
    parsedMembers.members
  );
  const modalRef = useRef(null);

  return (
    <section className="py-12 pt-32 -mt-14 xl:py-32" id="team">
      <div className="px-7">
        <div className="text-center mb-7 lg:mb-20">
          <h2 className="text-4xl font-bold lg:text-h2-hero-mobile">
            {heading}
          </h2>
          <p className="text-base font-fieldwork-hum lg:max-w-3xl lg:mx-auto lg:text-lg lg:leading-normal">
            {subheading}
          </p>
        </div>
        <div className="flex flex-wrap gap-4 md:gap-6 max-w-x-big lg:mx-auto lg:gap-8 xl:px-4 2xl:gap-12">
          {parsedMembers.members.length > 0 &&
            parsedMembers.members.map((member) => (
              <button
                type="button"
                key={member._uid}
                id={member._uid}
                className={styles.card}
                onClick={handleOpenModal}
                style={{ backgroundImage: `url(${member.photo?.image})` }}
              >
                <div
                  className={`${styles.cardMemberInfo} px-2 pt-4 pb-3 lg:pl-7 lg:py-7 bg-white absolute left-0 bottom-0 w-full`}
                >
                  <p className="leading-tight lg:text-xl text-left">
                    {member.name}
                  </p>
                  <p className="text-tiny text-secondary font-semibold lg:text-base text-left">
                    {member.position}
                  </p>
                </div>
              </button>
            ))}
        </div>
        {member && (
          <RemoveScroll enabled={open}>
            <MemberModal
              member={member}
              open={open}
              closeHandler={handleCloseModal}
              onClose={handleCloseModal}
              center
              showCloseIcon={false}
              blockScroll={false}
              ref={modalRef}
            />
          </RemoveScroll>
        )}
      </div>
    </section>
  );
}

export default OurTeamSection;
