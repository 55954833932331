import React, { ReactNode, useEffect, useRef } from "react";
import clsx from "clsx";
import { RemoveScroll } from "react-remove-scroll";

import { useMatchMediaContext } from "@/context";

import { CategoriesList } from "../types";

interface Props {
  categories: CategoriesList;
  activeFilter: string;
  onChange: (category: string) => void;
  isVisible: boolean;
  closeMenu: () => void;
  searchComponent: ReactNode;
}

export function PortfolioCategories({
  categories,
  activeFilter,
  onChange,
  isVisible,
  closeMenu,
  searchComponent,
}: Props) {
  const { isTablet } = useMatchMediaContext();
  const filterRef = useRef<HTMLDivElement>(null);

  function handleOnChangeCategory(category: string) {
    onChange(category);
    closeMenu();
  }

  useEffect(() => {
    closeMenu();
  }, [isTablet]);

  return (
    <RemoveScroll
      enabled={isVisible}
      className={clsx(
        "w-screen h-screen fixed top-0 left-0 z-50 pt-20 bg-primary text-white transition-all duration-300 ease-in-out lg:static lg:w-auto lg:h-auto lg:pt-0 lg:visible lg:opacity-100 lg:bg-transparent lg:mx-0",
        {
          "visible opacity-100": isVisible,
          "invisible opacity-0": !isVisible,
        }
      )}
      ref={filterRef}
    >
      <div className="lg:hidden absolute right-12 top-12">
        <button
          className="block cursor-pointer relative w-4 h-4"
          aria-expanded={isVisible ? true : false}
          onClick={closeMenu}
          aria-labelledby="close"
        >
          <span hidden id="close">
            Close
          </span>
          <span
            className="block absolute h-0.5 w-4 bg-white transition-all duration-300 ease-in-out rotate-45 top-2 opacity-40"
            aria-hidden="true"
          ></span>
          <span
            className="block absolute h-0.5 w-4 bg-white transition-all duration-300 ease-in-out -rotate-45 top-2 opacity-40"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div className="lg:flex lg:items-center">
        <ul className="flex flex-col items-center mt-8 text-white lg:w-4/5 lg:flex-row lg:justify-between lg:mt-0 lg:text-primary lg:border-b lg:border-grey-1">
          {categories &&
            Object.keys(categories).map((category) => {
              const isActive = activeFilter === category;
              return (
                <li
                  key={category}
                  className={clsx("mb-5 lg:mb-0", {
                    hidden: category === "all" && !isTablet,
                  })}
                >
                  <button
                    onClick={() => handleOnChangeCategory(category)}
                    className={clsx("flex items-center h-10", {
                      "border-b-2 lg:border-b-0 lg:text-secondary": isActive,
                    })}
                  >
                    <span className="text-xl leading-none uppercase lg:capitalize lg:text-lg">
                      {categories[category].name}
                    </span>
                    <div
                      className={clsx(
                        "ml-4 mb-0.5 w-4 h-4 leading-5 text-xtiny rounded-full bg-white text-primary lg:w-6 lg:h-6 lg:leading-6 lg:text-tiny lg:bg-secondary lg:text-white lg:opacity-50",
                        {
                          "lg:inline-block": isActive,
                          "lg:hidden": !isActive,
                        }
                      )}
                    >
                      {categories[category].amount}
                    </div>
                  </button>
                </li>
              );
            })}
        </ul>
        {isTablet && searchComponent}
      </div>
    </RemoveScroll>
  );
}
