import React from "react";
import SbEditable from "storyblok-react";

import StoryblokComponent from "@/base/utils/connectSbComponent";

export default function PageComponent({ body }: PageTemplate) {
  return (
    <div>
      {body &&
        body.map((item) => {
          return (
            <SbEditable key={item._uid} content={item}>
              <StoryblokComponent blok={item} />
            </SbEditable>
          );
        })}
    </div>
  );
}
