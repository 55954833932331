/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from "react";
import clsx from "clsx";

import LinkComponent from "@/components/elements/Link";
import { Dropdown } from "@/components/elements/Dropdown";
import { useLanguageContext } from "@/context";

import * as styles from "./styles.module.css";

interface Props {
  links: (LinkType | DropdownType)[];
  open: boolean;
  onClick: () => void;
  hash?: string;
  alternatePath?: string;
  path: string;
}

export default function MainNav({
  links = [],
  open,
  onClick,
  hash,
  alternatePath,
  path,
}: Props) {
  const {
    state: { language, languages, DEFAULT_LANG },
  } = useContext(useLanguageContext);

  function updateAlternatePath(lang: Language) {
    if (alternatePath) {
      return alternatePath;
    }
    return lang === DEFAULT_LANG ? "" : lang;
  }
  const langPrefix = language === DEFAULT_LANG ? "" : `/${language}`;

  return (
    <nav
      className={clsx(
        "w-screen fixed top-0 left-0 z-40 pt-12 bg-white bg-opacity-90 transition-all duration-300 ease-in-out xl:static xl:w-auto xl:h-auto xl:pt-0 xl:opacity-100 xl:visible xl:z-0 xl:transition-none xl:ml-1 overflow-auto xl:overflow-visible",
        open ? "h-screen visible opacity-100" : "h-0 invisible opacity-0"
      )}
    >
      <ul className="h-full flex flex-col items-center justify-center xl:justify-start xl:flex-row">
        {links.map((link) =>
          link.component === "element_dropdown" ? (
            <li
              key={link._uid}
              className="py-5 md:text-4xl xl:text-md xl:mr-10 2xl:mr-14"
            >
              <div className="relative text-center">
                <Dropdown {...link} inline className={styles.dropdown} />
              </div>
            </li>
          ) : (
            <li
              key={link._uid}
              onClick={onClick}
              className="xl:last:mr-0 xl:mr-10 2xl:mr-14"
            >
              <LinkComponent
                isButton={link.isButton}
                url={langPrefix + link.url}
                type={link.type}
                isCurrent={!!hash && link.url.indexOf(hash) >= 0}
                activeclassname="block relative after:w-4 xl:text-secondary xl:after:absolute xl:after:block xl:after:left-1/2 xl:after:bottom-4 xl:after:h-0.5 xl:after:bg-secondary xl:after:-ml-2 xl:text-md"
              >
                {link.isButton ? (
                  link.title
                ) : (
                  <button className="py-3 font-light tracking-wider relative md:text-4xl xl:text-md xl:py-5 xl:whitespace-pre xl:hover:text-secondary xl:hover:after:w-4 xl:hover:after:absolute xl:hover:after:block xl:hover:after:left-1/2 xl:hover:after:bottom-4 xl:hover:after:h-0.5 xl:hover:after:bg-secondary xl:hover:after:-ml-2">
                    {link.title}
                  </button>
                )}
              </LinkComponent>
            </li>
          )
        )}
        <li className="mt-20 xl:mt-0">
          {languages.map((lang, idx) => (
            <React.Fragment key={lang}>
              <LinkComponent
                type="internal"
                url={language === lang ? path : `/${updateAlternatePath(lang)}`}
                activeclassname="text-secondary"
              >
                <button
                  className="uppercase font-bold"
                  disabled={lang === language}
                >
                  {lang}
                </button>
              </LinkComponent>
              {idx + 1 < languages.length && (
                <span className="text-primary"> | </span>
              )}
            </React.Fragment>
          ))}
        </li>
      </ul>
    </nav>
  );
}
