import React, { useContext } from "react";

import LinkComponent from "@/components/elements/Link";
import MarkdownComponent from "@/components/elements/Markdown";
import SocialLink from "@/components/elements/SocialLink";
import { useLanguageContext } from "@/context";

export default function Footer({
  lead_logo,
  lead_content,
  menu = [],
  qr_code,
  socials = [],
  copy_rights,
}: FooterTemplate) {
  const {
    state: { language, DEFAULT_LANG },
  } = useContext(useLanguageContext);
  const langPrefix = language === DEFAULT_LANG ? "" : `/${language}`;

  return (
    <footer className="bg-primary py-16 text-white">
      <div className="px-8 lg:mx-auto max-w-x-big">
        <div className="lg:flex lg:mb-16">
          <div className="lg:w-2/5 xl:w-1/3">
            <div className="w-36 mb-10">
              <img src={lead_logo?.filename} alt={lead_logo?.alt} />
            </div>
            <p className="text-xs opacity-60 mb-9">{lead_content}</p>
          </div>
          <div className="hidden lg:w-1/5 lg:ml-28 lg:text-lg lg:flex lg:flex-col lg:items-center xl:w-1/3 xl:px-20 xl:border-r xl:border-l xl:border-white xl:border-opacity-30">
            <ul>
              {menu.map((item) => (
                <li key={item._uid}>
                  <LinkComponent
                    url={langPrefix + item.url}
                    type={item.type}
                    title={item.title}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="lg:w-2/5 lg:ml-28 xl:w-1/3">
            <div className="mb-16">
              {qr_code && (
                <img
                  className=" mx-auto w-48 lg:mx-0"
                  src={qr_code.filename}
                  alt={qr_code.alt}
                />
              )}
            </div>
            <div className="mb-16 lg:mb-0">
              <ul className="flex justify-center lg:justify-start">
                {socials.map((item) => (
                  <li
                    key={item._uid}
                    className="w-9 h-9 p-2 bg-white mr-6 last:mr-0 rounded-full"
                  >
                    <SocialLink {...item} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="text-white text-sm opacity-25 font-light">
          {copy_rights && <MarkdownComponent>{copy_rights}</MarkdownComponent>}
        </div>
      </div>
    </footer>
  );
}
