import React from "react";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import { useMatchMediaContext } from "@/context";
import ArrowDown from "@/assets/svg/arrow-down-hero.svg";
import * as styles from "./styles.module.css";
import { SideWindow } from "./SideWindow";

export default function HeroSection({
  heading,
  subheading,
  bg_image,
  bottom_label,
  featured_post,
}: HeroSectionType) {
  const { isTablet } = useMatchMediaContext();
  return (
    <>
      <Helmet>
        <link rel="preload" href={bg_image?.image} as="image" />
        <link rel="preload" href={ArrowDown} as="image" />
      </Helmet>
      <section className={styles.hero}>
        <div
          style={{ backgroundImage: `url(${bg_image?.image})` }}
          className={styles.wrapper}
        >
          <div className="w-full h-full bg-black bg-opacity-70">
            <div className={styles.heroContent}>
              <h1
                className={`${styles.heroHeader} italic font-black text-h1-hero-mobile font-fieldwork mb-4 break-words leading-none tracking-tight lg:tracking-normal lg:text-10xl`}
              >
                {heading}
              </h1>
              <p
                className={clsx(
                  "italic font-bookman-old text-xxl leading-tight px-3 md:px-12 md:mb-16 lg:mb-20",
                  styles.heroSubheading
                )}
              >
                {subheading}
              </p>

              <div className={clsx("hidden md:block", styles.heroArrowWrapper)}>
                {bottom_label && (
                  <p className="uppercase font-semibold tracking-widest text-center mb-3 font-fieldwork md:text-sm lg:text-lg">
                    {bottom_label}
                  </p>
                )}
                <div className="md:flex md:justify-center">
                  <img src={ArrowDown} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isTablet && bottom_label && (
          <p className="uppercase font-semibold tracking-widest text-center text-tiny font-fieldwork bg-light-grey pt-8">
            {bottom_label}
          </p>
        )}
        {isTablet && <SideWindow postId={featured_post} />}
      </section>
    </>
  );
}
