import { useEffect, useState, useCallback } from "react";
import { StoryData } from "storyblok-js-client";

import { Storyblok } from "@/base/lib/storyblok";

export default function useAuthorNews(uuids: string[]) {
  const [list, setList] = useState<
    (PostTemplate & { url: string; uuid: string })[]
  >([]);
  //   const getLastPostsByAuthor = useCallback(async () => {
  //     await Storyblok.get("cdn/stories/", {
  //       version: "published",
  //       sort_by: "published_at:desc",
  //       filter_query: {
  //         component: { in: "common_post", starts_with: `en/news/` },
  //         post_author: {
  //           like: authorId,
  //         },
  //       },
  //       per_page: 6,
  //     })
  //       .then(({ data }: { data: { stories: StoryData<CommonPostType>[] } }) => {
  //         if (data.stories) {
  //           const newList = data.stories.map((item) => ({
  //             ...item.content,
  //             uuid: item.uuid,
  //             url: item.slug,
  //           }));
  //           setList(newList);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   }, [authorId]);

  const getPostsByUuid = useCallback(async () => {
    const parsedUuids = uuids.join(",");

    if (parsedUuids) {
      await Storyblok.getStories({
        by_uuids: parsedUuids,
        version: "published",
        sort_by: "published_at:desc",
        per_page: 6,
      })
        // @ts-ignore
        .then(({ data }: { data: { stories: StoryData<PostTemplate>[] } }) => {
          if (data.stories) {
            const newList = data.stories.map((item) => ({
              ...item.content,
              uuid: item.uuid,
              url: item.slug,
            }));
            setList(newList);
          }
        })
        .catch((err) => console.log(2, err));
    }
  }, [uuids]);

  useEffect(() => {
    //  getLastPostsByAuthor();
    getPostsByUuid();
  }, [uuids]);

  return list;
}
