import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import LinkComponent from "@/components/elements/Link";
import { useFeaturedPost } from "@/hooks/useLatestNews";
import { useLanguageContext } from "@/context";
import * as styles from "./styles.module.css";

interface Props {
  postId: string;
}

export function SideWindow({ postId }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    state: { language, DEFAULT_LANG },
  } = useContext(useLanguageContext);
  const latestPost = useFeaturedPost(postId, language);

  const articleUrl =
    language === DEFAULT_LANG
      ? `/news/${latestPost?.slug}`
      : `/${language}/news/${latestPost?.slug}`;

  const toggleWindow = () => {
    setIsOpen(!isOpen);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key.toLowerCase() === "enter") {
      toggleWindow();
    }
  };

  useEffect(() => {
    if (latestPost?.name) {
      setIsOpen(true);
    }
  }, [latestPost]);

  return (
    <div
      className={clsx(
        "text-white text-left py-5 pl-16 pr-4 xl:max-w-md lg:max-w-xs absolute lg:top-16 bg-black bg-opacity-50 rounded-r-xl transition-all duration-300 ease-in-out",
        styles.container,
        {
          [styles.closed]: !isOpen,
          "translate-x-0": isOpen,
        }
      )}
      onClick={toggleWindow}
      onKeyUp={onKeyDownHandler}
      role="button"
      tabIndex={-1}
    >
      <div className="relative pr-5">
        {isOpen && (
          <button type="button" onClick={toggleWindow} aria-labelledby="close">
            <span hidden id="close">
              Close
            </span>
            <span
              className="block absolute top-0 right-0 h-px w-3 bg-white border border-white rotate-45 transition-all duration-300 ease-in-out"
              aria-hidden="true"
            ></span>
            <span
              className="block absolute top-0 right-0 h-px w-3 bg-white border border-white -rotate-45 transition-all duration-300 ease-in-out"
              aria-hidden="true"
            ></span>
          </button>
        )}
        <p className="text-sm mb-6">Latest News</p>
        <p className="xl:text-3xl lg:text-base italic font-bookman-old leading-normal mb-7">
          {latestPost?.name}
        </p>
        <div
          className={clsx(
            "text-secondary text-sm font-semibold",
            styles.wrapper
          )}
        >
          {latestPost?.content?.external_post
            ? latestPost?.content?.external_link && (
                <LinkComponent
                  url={latestPost?.content?.external_link}
                  type="external"
                >
                  Read more
                  <div className="arrow"></div>
                </LinkComponent>
              )
            : latestPost?.full_slug && (
                <LinkComponent url={articleUrl} type="internal">
                  Read more
                  <div className="arrow"></div>
                </LinkComponent>
              )}
        </div>
      </div>
    </div>
  );
}
