import React from "react";
import Img from "gatsby-image/withIEPolyfill";

import {
  getFixedGatsbyImage,
  getFluidGatsbyImage,
} from "gatsby-storyblok-image";

interface Props {
  fluid?: boolean;
  fixed?: boolean;
  className?: string;
  alt?: string;
  copyright_info?: string;
  data: StoryblokGatsbyImageAsset;
  options?: {
    width?: number;
    height?: number;
    maxWidth?: number;
    maxHeight?: number;
    quality?: number;
  };
  style?: React.CSSProperties;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down" | undefined;
  objectPosition?: string;
}

export function ImageComponent({
  fluid,
  fixed,
  data,
  options = {},
  ...rest
}: Props) {
  const fixedProps =
    fixed && data
      ? getFixedGatsbyImage(data, {
          ...options,
          format: "webp",
          toFormat: "webp",
        })
      : null;

  const fluidProps =
    fluid && data
      ? getFluidGatsbyImage(data, {
          ...options,
          format: "webp",
          toFormat: "webp",
        })
      : null;

  return fixedProps ? (
    <Img fixed={fixedProps} {...rest} alt={data.alt_tag || ""} />
  ) : fluidProps ? (
    <Img fluid={fluidProps} {...rest} alt={data.alt_tag || ""} />
  ) : null;
}
