import React from "react";
import { ImageComponent } from "@/components/elements/Image";
import MarkdownComponent from "@/components/elements/Markdown";

import LinkComponent from "@/components/elements/Link";
import * as styles from "./styles.module.css";

function ContactUs({
  label,
  title,
  image,
  addresses,
  additional_links,
  offices_links,
  offices_title,
}: ContactUsType) {
  return (
    <section className="pt-8 pb-16 md:pt-16 lg:pt-20">
      <div className={styles.wrapper}>
        <div>
          <span className="text-secondary font-semibold font-fieldwork-hum leading-5 text-md uppercase tracking-widest block">
            {label}
          </span>
          <h1 className="font-fieldwork text-h1-hero-mobile leading-none font-bold mt-6 mb-8 lg:text-8xl">
            {title}
          </h1>
          {Array.isArray(addresses) && (
            <div className="lg:grid lg:grid-cols-2 lg:gap-2">
              {addresses.map((item) => (
                <MarkdownComponent key={item._uid} className={styles.address}>
                  {item.content || "Empty"}
                </MarkdownComponent>
              ))}
            </div>
          )}
        </div>

        <div className={styles.imageWrapper}>
          {image && <ImageComponent fluid data={image} />}
        </div>

        {Array.isArray(additional_links) && (
          <div className={styles.additional_links}>
            {additional_links.map((item) => (
              <LinkComponent
                key={item._uid}
                type={item.type}
                url={item.url}
                className="flex items-center text-secondary leading-7 text-lg font-fieldwork-hum font-semibold  capitalize lg:text-lg"
              >
                <span className="block mr-3">{item.title}</span>
                <span
                  className="arrow"
                  style={{
                    width: 32,
                  }}
                ></span>
              </LinkComponent>
            ))}
          </div>
        )}

        {Array.isArray(offices_links) && !!offices_links.length ? (
          <div className={styles.offices_links}>
            <h2 className="font-bookman-old leading-10 font-light mb-8">
              {offices_title}
            </h2>

            <div className="grid grid-cols-none gap-7 lg:gap-8">
              {offices_links.map((item) => (
                <LinkComponent
                  key={item._uid}
                  type={item.type}
                  url={item.url}
                  className="flex items-center text-secondary leading-7 text-lg font-fieldwork-hum font-semibold  capitalize lg:text-lg"
                >
                  <span className="block mr-3">{item.title}</span>
                  <span
                    className="arrow"
                    style={{
                      width: 32,
                    }}
                  ></span>
                </LinkComponent>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default ContactUs;
