import React, { ForwardedRef, useRef } from "react";
import Modal from "react-responsive-modal";
import clsx from "clsx";
import CloseIcon from "@/assets/svg/close.svg";
import { useMatchMediaContext } from "@/context";
import "react-responsive-modal/styles.css";
import SocialLink from "@/components/elements/SocialLink";
import { ImageComponent } from "@/components/elements/Image";
import MarkdownComponent from "@/components/elements/Markdown";
import * as styles from "./styles.module.css";
import { CompaniesList } from "./CompaniesList";

interface Props {
  member: MemberType;
  closeHandler: () => void;
  onClose: () => void;
  open: boolean;
  showCloseIcon: boolean;
  blockScroll: boolean;
  center: boolean;
}

const MemberModal = React.forwardRef(
  (
    { member, closeHandler, ...rest }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { isTablet } = useMatchMediaContext();
    const wrapperRef = useRef(null);
    const {
      description,
      name,
      position,
      socials,
      companies = [],
      photo,
    } = member;

    return (
      <div ref={wrapperRef}>
        <Modal
          {...rest}
          focusTrapped
          initialFocusRef={wrapperRef.current || undefined}
          container={wrapperRef.current}
          classNames={{ modal: styles.teamModal }}
          ref={ref}
        >
          <div>
            <div className="flex justify-end mb-2 lg:px-4">
              <button
                type="button"
                className="h-5 w-5"
                onClick={closeHandler}
                aria-labelledby="close"
              >
                <span hidden id="close">
                  Close
                </span>
                <img src={CloseIcon} alt="" aria-hidden="true" />
              </button>
            </div>

            <div className="lg:flex lg:justify-between lg:items-center lg:px-14 lg:pb-10 lg:pt-4">
              <div className="lg:w-1/2 lg:mr-12 xl:mr-0">
                <h2 className="text-xxl leading-none lg:text-6xl lg:leading-tight lg:font-light">
                  {name}
                </h2>
                <p className="text-secondary font-bold text-sm mb-3 lg:text-2xl lg:mb-10 lg:font-semibold">
                  {position}
                </p>

                <div
                  className={clsx(
                    "text-sm font-fieldwork-hum font-light leading-relaxed pb-6 lg:pb-0 lg:text-md lg:leading-snug lg:mb-10 h-52 overflow-y-scroll pr-2",
                    styles.content
                  )}
                >
                  {description && (
                    <MarkdownComponent>{description}</MarkdownComponent>
                  )}
                </div>

                {companies.length > 0 && (
                  <CompaniesList companies={companies} />
                )}

                <ul className="flex mt-8 lg:mt-16">
                  {socials &&
                    socials.map((social) => (
                      <li
                        key={social._uid}
                        className={clsx(
                          "w-8 h-8 mr-6 bg-black last:mr-0 rounded-full",
                          styles.icon
                        )}
                      >
                        <SocialLink {...social} />
                      </li>
                    ))}
                </ul>
              </div>

              {isTablet && (
                <div className="lg:w-1/2 lg:rounded-full lg:max-w-md">
                  {photo && (
                    <ImageComponent
                      fluid
                      data={photo}
                      options={{
                        maxWidth: 429,
                        quality: 70,
                      }}
                      style={{
                        borderRadius: "9999px",
                        height: 0,
                        paddingBottom: "100%",
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);
export default MemberModal;
