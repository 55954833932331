import React from "react";
import Markdown, { MarkdownToJSX } from "markdown-to-jsx";

import DownloadLink from "../DownloadLink";

interface Props extends MarkdownToJSX.Options {
  children: string;
  className?: string;
}

export default function MarkdownComponent(props: Props) {
  return (
    <Markdown
      className={props.className}
      options={{
        forceWrapper: true,
        forceBlock: true,
        overrides: {
          DownloadLink: {
            component: DownloadLink,
          },
          a: CustomLink,
          ...props.overrides,
        },
      }}
      {...props}
    />
  );
}

const CustomLink = ({ href, children }: { href: string; children: string }) => {
  return (
    <a
      href={href}
      rel="noreferrer"
      target={href && href.substring(0, 6) === "mailto" ? "_self" : "_blank"}
    >
      {children}
    </a>
  );
};
