/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import clsx from "clsx";
import find from "lodash/find";
import { RemoveScroll } from "react-remove-scroll";
import { useMatchMediaContext } from "@/context";
import LinkComponent from "@/components/elements/Link";
import unknownIcon from "@/assets/img/unknown-icon.png";
import websiteIcon from "@/assets/svg/export.svg";

import PortfolioModal from "./Modal";
import * as styles from "./styles.module.css";

const SLIDER_SETTINGS: Settings = {
  arrows: false,
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  rows: 2,
  slidesPerRow: 2,
  className: "test",
  appendDots: (dots) => {
    return (
      <div>
        <ul className={styles.paginationList}>{dots}</ul>
      </div>
    );
  },
  customPaging: () => {
    return <button type="button" aria-label="slider_dot" />;
  },
};

interface Props {
  projects: PortfolioProjectType[];
}

export function PortfolioList({ projects }: Props) {
  const { isTablet } = useMatchMediaContext();
  const [projectDetails, setProjectDetails] = useState<PortfolioProjectType>();
  const [modalVisible, handleModalVisible] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  function handleOpenModal(projectId: string) {
    if (!projectId) {
      return;
    }
    const targetedProject = find(projects, { uuid: projectId });
    setProjectDetails(targetedProject);
    handleModalVisible(true);
  }

  function handleCloseModal() {
    setProjectDetails(undefined);
    handleModalVisible(false);
  }
  return (
    <>
      {isTablet ? (
        <div className="flex flex-wrap items-center justify-between lg:gap-8 lg:justify-start xl:gap-10">
          {projects.map(
            ({
              uuid,
              project_category,
              project_socials,
              project_logo,
              project_acquiredBy,
            }) => {
              const categoriesData =
                project_category && project_category.length
                  ? project_category.filter((item) => !!item)
                  : [];
              const isRecent =
                categoriesData
                  .map((item) => item.toLowerCase())
                  .indexOf("recent") >= 0 && "Recent";
              const isExited =
                categoriesData &&
                categoriesData
                  .map((item) => item.toLowerCase())
                  .some((item) => item.indexOf("exited") >= 0) &&
                "Listed/Exited";
              const projectWeb =
                project_socials &&
                project_socials.filter(
                  (social) => social.type === "website"
                )[0];
              return (
                <div
                  key={uuid}
                  className={styles.projectWrapper}
                  onClick={() => handleOpenModal(uuid)}
                >
                  <div
                    className={clsx("absolute right-0 top-0 text-white z-10", {
                      "bg-exitedBadge !tracking-small !text-tiny": isExited,
                      "bg-recentBadge": isRecent,
                    })}
                  >
                    {isExited || isRecent}
                  </div>
                  <img src={project_logo?.filename || unknownIcon} alt="" />
                  <div className="mt-auto flex w-full justify-between items-center opacity-40 lg:py-2 xl:py-6 lg:border-t">
                    <p className="lg:text-xxs xl:text-sm tracking-normal font-fieldwork-hum leading-none">
                      {project_acquiredBy ? (
                        <span>Acquired by {project_acquiredBy}</span>
                      ) : null}
                    </p>
                    {projectWeb && (
                      <LinkComponent url={projectWeb.url} type="external">
                        <img src={websiteIcon} alt="Visit website" />
                      </LinkComponent>
                    )}
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : (
        <Slider {...SLIDER_SETTINGS}>
          {projects.map(({ uuid, project_category, project_logo }) => {
            const categoriesData =
              project_category && project_category.length
                ? project_category.filter((item) => !!item)
                : [];
            const isRecent = categoriesData.indexOf("Recent") >= 0 && "Recent";
            const isExited =
              categoriesData &&
              categoriesData
                .map((item) => item.toLowerCase())
                .some((item) => item.indexOf("exited") >= 0) &&
              "Listed/Exited";
            return (
              <div
                key={uuid}
                className={styles.projectWrapper}
                onClick={() => handleOpenModal(uuid)}
              >
                <div
                  className={clsx("absolute right-0 top-0 text-white z-10", {
                    "bg-exitedBadge": isExited,
                    "bg-recentBadge": isRecent,
                  })}
                >
                  {isExited || isRecent}
                </div>
                <img src={project_logo?.filename || unknownIcon} alt="" />
              </div>
            );
          })}
        </Slider>
      )}
      {projectDetails && (
        <RemoveScroll enabled={modalVisible}>
          <PortfolioModal
            open={modalVisible}
            onClose={handleCloseModal}
            projectDetails={projectDetails}
            isTablet={isTablet}
            ref={modalRef}
          />
        </RemoveScroll>
      )}
    </>
  );
}
