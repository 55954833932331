import React from "react";

import downloadIco from "@/assets/svg/download-button.svg";

interface Props {
  url: string;
  title: string;
  isIcon: boolean;
}

export default function DownloadLink({ url, title, isIcon }: Props) {
  return (
    <a
      href={url}
      target="blank"
      download
      className="inline-block w-auto relative pb-2 font-bold leading-none break-words before:block before:w-full before:absolute before:bottom-0 before:left-0 before:h-px before:bg-black"
      rel="noopener noreferrer"
    >
      <span className="inline-block uppercase tracking-wide">{title}</span>
      {isIcon ? (
        <img
          src={downloadIco}
          alt="download icon"
          className="ml-5"
          style={{ maxWidth: 12 }}
        />
      ) : null}
    </a>
  );
}
